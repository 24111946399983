@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

* {
  margin: 0;
  padding: 0;
}

.location-search-input {
  width: 500px;
  height: 40px;
  background: none;

  border-radius: 5px;
  font-size: 1rem;
  color: black;
}

.autocomplete-dropdown {
  position: absolute;
  z-index: 10;
  margin-top: 10px;
  border-radius: 10px;
  background-color: white;
  width: 400px;
  border: 1px solid #c8c8c8;
  box-shadow: 2px 4px 8px 2px #a9a9a9;
}
.input-suggestion {
  font-family: 'Lato', sans-serif;
  width: 100%;
  padding: 10px 20px;

  border-bottom: 1px solid #d0d0d0;
  padding-left: 10px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  color: black;
}

.input-suggestion:last-child {
  border: none;
}
.text-description {
  margin-left: 2px;
}
