.iconActive {
  color: #ff824c;
}

.icon {
  color: #ff824c;
}

.icon:hover {
  color: #ff824c;
}

.iconTranslate:hover {
  transform: translateY(-2px);
}

.iconTranslate {
  transition: transform 500ms ease-in-out 25ms;
}
