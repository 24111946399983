body {
  margin: 0;
  padding: 0;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ff824c;
}

.auth-bg-gradient {
  background: rgb(253, 187, 45);
  background: linear-gradient(45deg, #f6ad55 0%, #7b341e 75%);
}

.pattern {
  background-color: #fff;
  background-image: radial-gradient(#ff824c 0.5px, transparent 0.5px),
    radial-gradient(#ff824c 0.5px, #fff 0.5px);
  background-size: 20px 20px;
  background-position: 0 0, 10px 10px;
}

.custom-tooltip {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  text-align: left;
}

.custom-tooltip h1 {
  color: #ff824c;
  font-size: 18px;
  font-weight: bold;
}

.react-joyride__tooltip button:focus {
  outline: none;
}

.ping {
  animation: blink 2s infinite;
  -webkit-animation: blink 2s infinite;
  -moz-animation: blink 2s infinite;
  -o-animation: blink 2s infinite;
}

@keyframes blink {
  0% {
    box-shadow: 0 0 1px 6px rgba(255, 130, 76, 0.5);
  }
  50% {
    box-shadow: 0px 0px 1px 0 transparent;
  }
  100% {
    box-shadow: 0 0 1px 6px rgba(255, 130, 76, 0.5);
  }
}
